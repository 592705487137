import { Component, OnInit, ElementRef } from '@angular/core';
import { NgForm, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app/authentication/authService';
import { userService } from 'app/services/users/user.service';
import { notifier } from 'app/services/helpers';


declare var $:any;

@Component({
    moduleId:module.id,
    selector: 'login-cmp',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit{
    test : Date = new Date();
    form;
    validUser = true;
    constructor(private fb: FormBuilder, private myRoute: Router, private auth: AuthService, private userService: userService){
    this.form = fb.group({
        username: ['', [Validators.required]],
        password: ['', Validators.required]
      });
    }

    checkFullPageBackgroundImage(){
        var $page = $('.full-page');
        var image_src = $page.data('image');

        if(image_src !== undefined){
            var image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>'
            $page.append(image_container);
        }
    };


    login() {
        if (this.form.valid) {     
            if(this.validUser){    
               // this.myRoute.navigate(["back/mangel1"]);
       
                this.userService.login(this.form.value.username, this.form.value.password).subscribe(response =>{
                    //@ts-ignore
                    this.auth.sendToken(response.token, response.roles, response.location, response.id)
                    var roles = this.auth.getRoles();
                    if(roles === 'External'){
                        this.myRoute.navigate(["mangelAdmin/admin"])
                    }
                    if(roles === 'Driver'){
                        this.myRoute.navigate(["routes/routes"])
                    }
                    else{
                        this.myRoute.navigate(["front/mangel1"]); 
                    }
                },            
                error => {
                    if (error.errorCode === 400) {
                        this.auth.logout()
                        notifier(error.errorMessage.message, 'error', 'danger')
                        return;
                    }
                    if(error.errorCode === 0){
                        notifier('Could not reach server, please contact administrator', 'error', 'danger')
                        return;
                    }
                    else {
                        this.auth.logout()
                        notifier(error.errorMessage, 'error', 'danger')
                        return;
                    }
                })
            }


        }
      }

    ngOnInit(){
        this.checkFullPageBackgroundImage();

        setTimeout(function(){
            // after 1000 ms we add the class animated to the login/register card
            $('.card').removeClass('card-hidden');
        }, 700)
    }
}
