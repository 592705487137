import { Component } from '@angular/core';
import { dataService } from './services/data/data.service';
import { Customer } from './models/customer';
import { notifier } from './services/helpers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  customers : Customer[];
  constructor(private dataService: dataService){

  }

  ngOnInit(){
    this.dataService.getCustomerData('Oldenzaal').subscribe(response => {
      localStorage.setItem('ENG_Customers', JSON.stringify(response));
    },
    error => {
      notifier('Er is iets misgegaan bij ophalen klanten, ververs het scherm en anders contact de beheeerder', 'error', 'danger');
      console.log(error)
    })
  }
}
