import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import {
  FilterService,
  PageService,
  FilterSettingsModel,
  SortService,
  RowSelectEventArgs,
  GridComponent,
  ResizeService,
  ToolbarItems,
  ExcelExportService,
  PdfExportService,
  Column,
  SelectionSettingsModel
} from '@syncfusion/ej2-angular-grids';
import {
  dataService
} from '../services/data/data.service';
import {
  Router
} from '@angular/router';
import {
  Invoice
} from '../models/invoice';
import {
  L10n,
  loadCldr,
  setCulture,
  setCurrencyCode
} from '@syncfusion/ej2-base';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { notifier } from '../services/helpers';
import { InvoiceLine } from '../models/invoiceLine';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss'],
  providers: [FilterService, PageService, SortService, ResizeService, ExcelExportService, PdfExportService]
})
export class BankComponent implements OnInit {
  public invoices: Invoice[] = [];
  public filterOptions: FilterSettingsModel;
  public toolbarOptions: ToolbarItems[];
  public period: string = '';
  @ViewChild('grid', {
    static: true
  })  public grid: GridComponent;
  public dateFrom:  Date;

  public selectionOptions: SelectionSettingsModel;
  public sortOptions: object;

  invoice: Invoice;
  sizes: any;
  item: any;
  invoiceDateString: string;
  // toggles between search or add
  addMode: boolean;
  findMode: boolean;
  containerWeights: any;
  formats: [];
  mergedLines: InvoiceLine[];
  totalExBTW: number;
  totalIncBTW: number;
  btwAmount: number;
  btw: number;
  paymentTerm: string;
  email: boolean;
  constructor(private dataService: dataService, private router: Router, private changeRef: ChangeDetectorRef,  private httpClient: HttpClient) {}

  ngOnInit() {
    this.period = null;
    this.dateFrom = null;
    this.loadData();
  }

  loadData(){
    setCurrencyCode('EUR');
    // this.initDatePickers();
    this.dataService.getInvoicesExact().subscribe(res => {
      this.sortOptions = { columns: [{ field: 'invoiceDate', direction: 'Ascending' }] };
      this.invoices = res;
      this.invoices.forEach(invoice => {
        this.mergedLines = [];
        this.totalExBTW = 0
        this.btw = 0
        this.totalIncBTW = 0
        this.btwAmount = 0
        invoice.invoiceLines.forEach(invoiceLine => {
          var foundLine: InvoiceLine = this.mergedLines.find(x => x.description === invoiceLine.description);
          if (foundLine) {
            foundLine.amount = foundLine.amount + invoiceLine.amount;
            foundLine.price = Number((foundLine.price + invoiceLine.price).toFixed(2));
            foundLine = null
          } else {
            invoiceLine.price = Number(invoiceLine.price.toFixed(2));
            this.mergedLines.push(invoiceLine);
          }
        })
        this.totalExBTW = Number(this.mergedLines.reduce((sum, current) => sum + current.price, 0).toFixed(2));
        this.btw = invoice.customer.buitenland ? 0 : 19;
        invoice.totalAmountInclVat =  this.totalExBTW * (this.btw / 100 + 1);
        invoice.totalAmountInclVat = Number(invoice.totalAmountInclVat.toFixed(2))
        invoice.totalAmountExVat = Number((invoice.totalAmountInclVat - this.totalExBTW).toFixed(2))
      })
      this.filterOptions = {
        mode: "Immediate"
      };
    })
  }

  dataBound() {

  }

  generateExact() {
    if(this.period === null){
      notifier('U heeft geen period ingevuld', 'error', 'danger');
      return;
    }
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem("LoggedInUser")}` 
    });
    var selectedrecords: Object[] = this.grid.getSelectedRecords()
    this.httpClient.post(environment.serviceUrl + '/api/invoice/generateExactFile/' + this.period,selectedrecords,  { headers: headers, responseType: 'blob' } ).subscribe((data: Blob) => {
      var file = new Blob([data], {
        type: 'text/csv'
      })
      var fileURL = URL.createObjectURL(file);
      // if you want to open PDF in new tab
      //window.open(fileURL);
      var a = document.createElement('a');
      a.href = fileURL;
      var intraBankFile = `FMUTA6-${new Date().toISOString().slice(0,10)}.csv`;
      a.download = intraBankFile
      document.body.appendChild(a);
      a.click();
    }),
    error => {
      this.loadData();

    }
    // this.dataService.generateExactFile(selectedrecords).subscribe(res => {
    //   console.log(res)
    // })
  }

  find(){
    var dateFrom = $('#datetimepicker1').data("DateTimePicker").viewDate().format("DD-MM-yyyy");
   // var dateTo = $('#datetimepicker2').data("DateTimePicker").viewDate().toDate();
   // this.invoices.filter(invoice => new Date(invoice.invoiceDate) >= dateFrom && new Date(invoice.invoiceDate) <= dateTo);
    //this.grid.refresh();
    this.grid.filterByColumn('invoiceDate', 'greaterThan', dateFrom)
    console.log(this.invoices, dateFrom)
  }

}