import { MangelFormat } from './mangelFormat';

export class Mangel1 {
        CustomerCode : string;
        Laken1P : number;
        Laken2P : number;
        Hoeslaken : number;
        Hoeslaken2P : number;
        Overtrek : number;
        Overtrek2P: number;
        Steeklaken : number;
        Opmerking : string;
        TotaalOpmerking: string;
        ContainerAmount: number;
        MandenAmount: number;
        Colli: number;
        HoezenAmount: number;
        BedPakkettenAmount: number;
        Date : Date;
        closed : boolean;
        front: boolean;
        mangelFormats: MangelFormat[];
}