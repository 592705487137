import { FormatPrice } from './formatPrice';
import { SpecialPrice } from './specialPrice';

export class Price {
      id: number;
      FormatPriceId : number;
      CustomerCode : string;
      Laken1P : number;
      Laken2P : number; 
      Hoeslaken : number; 
      Hoeslaken2P : number; 
      Overtrek : number; 
      Overtrek2P : number; 
      napparons : number; 
      slopen : number; 
      SlopenKlein : number; 
      Servetten : number; 
      Doeken : number; 
      Lopers  : number;
      Steeklaken : number; 
      Overall : number; 
      AMOverall : number; 
      WerkBroek : number; 
      WerkJas : number; 
      StofJas : number; 
      VestTrui : number; 
      KoksJas : number; 
      KoksBroek : number; 
      Sloof : number; 
      Overhemd : number; 
      Deken : number; 
      TafelRok : number; 
      Tshirt : number; 
      Doek : number; 
      Polo : number;
      Sweater: number;
      Merken: number;
      Werkhandschoenen: number;
      Reparaties: number;
      Pilotjack: number;
      Schoonloopmat: number;
      Bodywarmer: number;
      BadstofBadmat  : number;
      BadstofBaddoek : number; 
      BadstofBadlaken : number; 
      BadstofSaunalaken : number; 
      BadstofMolton : number; 
      BadstofJersey : number; 
      BadstofGastendoek : number; 
      BadstofWashand : number; 
      BadstofBadjas : number; 
      Kussen: number;
      Dekbed: number;
      Container: number;
      Colli: number;
      Hoezen: number;
      Manden: number;
      Bedpakketten1p: number;
      Bedpakketten2p: number;
      Transport:number;
      Restaurant: number;
      Bedlinnen: number;
      Badlinnen: number;
      Date : Date;
      formatPrices : FormatPrice[];    
      specialPrices: SpecialPrice[];
}