import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addTokenToRequest(request, localStorage.getItem("LoggedInUser")))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            // Attempt to refresh the token or handle 401 error
            return this.handle401Error(request, next);
          }
          return throwError(error);
        })
      );
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    const isFileUpload = request.body instanceof FormData;

    let headers = new HttpHeaders();
    if (token) {
        headers = headers.set('Authorization', `Bearer ${token}`);
    }
    // Do not set 'Content-Type' header if this is a file upload request
    if (!isFileUpload) {
        headers = headers.set('Content-Type', 'application/json');
    }
    return request.clone({ headers });
}


  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("LoggedInUser");
    if (!token) {
      // If you don't have a token, redirect to login
      this.router.navigate(['login']);
      return throwError(() => new Error('Unauthorized'));
    }

    // Retry with the new token
    return next.handle(this.addTokenToRequest(request, token));
  }
}
