// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = { 
  production: false,
 //serviceUrl: "http://localhost:56917"
  serviceUrl: "http://api.wascherei-engelbertink.de"
 //serviceUrl: "http://192.168.0.6:81/api"
 //serviceUrl: "http://localhost:5000"

 //serviceUrl: "http://api.wasserijengelbertink.nl/api"
};
