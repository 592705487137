import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  Customer
} from '../../models/customer';
import { notifier } from '../../services/helpers';
import { dataService } from '../../services/data/data.service';
import { PackingSlipHistoryModel } from '../../models/packingSlipHistoryModel';
import { PackingSlip } from '../../models/packingSlip';
import { Price } from '../../models/price';
import { historyLine } from '../../models/historyLine';
import { generateStaticService } from '../../services/data/helper.services';
import { SpecialPriceType } from '../../models/specialPriceType';
import html2pdf from 'html2pdf.js'
import { UploadServiceService } from '../../services/upload-service.service';

@Component({
  selector: 'app-credit-history',
  templateUrl: './credit-history.component.html',
  styleUrls: ['./credit-history.component.scss']
})
export class CreditHistoryComponent implements OnInit {
  @ViewChild('auto', {
    static: true
  }) auto;
  selectedCustomer: Customer;
  customers: Customer[];
  startDate: Date;
  endDate: Date;
  packingSlips: PackingSlip[];
  totalMangel:historyLine[];
  prices: Price;
  formatPrices: any;
  sizes: any;
  specialPriceTypes: SpecialPriceType[];
  totalHistory: historyLine[];
  packingslipTotals: any;
  hasData: boolean;
  packingslipNrs: any;
  keyword = 'customerNameMerged';
  public customerName = '';
  constructor(private dataService: dataService, private helperService: generateStaticService, private uploadService: UploadServiceService) {}


  ngOnInit() {
    this.selectedCustomer = new Customer();
    this.hasData = false;
    //@ts-ignore
    $('.datetimepicker').datetimepicker({
      icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-chevron-up",
          down: "fa fa-chevron-down",
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
      },
      locale: 'NL',
      format: 'LL'
    });
    //@ts-ignore
    $('.datetimepicker2').datetimepicker({
      icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-chevron-up",
          down: "fa fa-chevron-down",
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
      },
      locale: 'NL',
      format: 'LL'
    });
    this.dataService.getSpecialPriceTypes().subscribe(res => {
      this.specialPriceTypes = res;

    });
    this.sizes = this.helperService.getServices();
    this.dataService.getCustomerData('Oldenzaal').subscribe(res => {
      this.customers = res
      for (let key in this.customers) {
        if (this.customers.hasOwnProperty(key)) {
          this.customers[key].customerNameMerged = this.customers[key].customerCode + " | " + this.customers[key].customerName;
        }
  
      }
    });

  }

  getHistory(){
    if(Object.keys(this.selectedCustomer).length === 0){
      notifier('Er moet een klant gekozen zijn', 'error', 'danger');
      return;
    }
    if($('.datetimepicker').data("DateTimePicker").date() === null || $('.datetimepicker2').data("DateTimePicker").date() === null )
    {
      notifier('Start en einddatum moet zijn ingevuld', 'error', 'danger');
      return;
    }
    this.startDate = $('.datetimepicker').data("DateTimePicker").date().toDate();
    this.endDate = $('.datetimepicker2').data("DateTimePicker").date().toDate();
    var historyModel = new PackingSlipHistoryModel();
    historyModel.customerId = this.selectedCustomer.id;
    historyModel.startDate = this.startDate;
    historyModel.endDate = this.endDate;
    this.dataService.getCreditHistory(historyModel).subscribe(packingSlips => {
      this.packingSlips = packingSlips;
      this.processData(this.packingSlips);
    },
    error => {
      notifier("Fout tijdens ophalen pakbonnen", 'error', 'danger')
    })
  }

  email(){
    var element = document.getElementById('element-to-print');
    var opt = {
      margin: 0.2,
      filename: 'Overzicht-' + this.selectedCustomer.customerName + '.pdf',
      image: {
        type: 'jpeg',
        quality: 1
      },
      enableLinks: true,
      html2canvas: {
        scale: 2,
        dpi: 192,
        letterRendering: true
      },
      jsPDF: {
        unit: 'in',
        format: 'a4',
        orientation: 'portrait'
      }
    };
    html2pdf().set(opt).from(element).outputPdf().then(pdf => {
      let formData = new FormData();
      formData.append('file', btoa(pdf));
      formData.append('customerEmail', this.selectedCustomer.email);
      formData.append('customerName', this.selectedCustomer.customerName);
      formData.append('startDate', this.startDate.toDateString());
      formData.append('endDate', this.endDate.toDateString());
      this.uploadService.sendPackingSlipHistory(formData).subscribe(res => {
          notifier('Overzicht succesvol verstuurd', 'save', 'info');
        },
        error => {
          notifier('Er is iets misgegaan, probeer opnieuw of contact de beheeerder', 'error', 'danger');
          console.log(error)
        })
    })
  }

  print(){
    var element = document.getElementById('element-to-print');
    var opt = {
      margin:       0.2,
      filename:      'Overzicht-' + this.selectedCustomer.customerName + '.pdf',
      image:        { type: 'jpeg', quality: 1 },
      enableLinks:  true,
      html2canvas:  { scale: 2, dpi:192, letterRendering: true },
      jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' },
      pagebreak: { before: '#page2el' }
    };

    // New Promise-based usage:
    html2pdf().set(opt).from(element).output('pdf').save();
  }

  processData(packingSlips: PackingSlip[]){
    this.totalMangel = [];
    this.packingslipNrs = packingSlips.map(x => x.packingSlipNr).join();
    packingSlips.forEach(packingslip => {
      this.clean(packingslip.mangel1);
      this.clean(packingslip.mangel2);
      this.clean(packingslip.mangel3);
      this.clean(packingslip.mangel4);
      this.clean(packingslip.badstofVouwer);
      // loop through all mangels and add to invoice amounts, can be refactored later to 1 function
      for (var name in this.prices) {
        for (var packingSlipIndex in packingslip.mangel1) {
          if (packingSlipIndex.replace('Amount', '').toLowerCase() == name.replace('1p', '').replace('2p', '2').toLowerCase() && name != 'customerCode' && name != 'date' && name != 'id') {
            if(packingSlipIndex === 'transportAmount'){
              this.totalMangel.push({
                price: this.getPrice('transport'),
                amount: Number(packingslip.mangel1[packingSlipIndex]),
                description: this.CreateCorrectDescription(name),
              })
            }
            else{
              this.totalMangel.push({
                price: this.getPrice(packingSlipIndex),
                amount: Number(packingslip.mangel1[packingSlipIndex]),
                description: this.CreateCorrectDescription(name),
              })  
            }
          }
        }
        for (var packingSlipIndex in packingslip.mangel2) {
          if (packingSlipIndex.replace('Amount', '').toLowerCase() == name.replace('1p', '').replace('2p', '2').toLowerCase() && name != 'customerCode' && name != 'date' && name != 'id') {
            this.totalMangel.push({
              price:  this.getPrice(packingSlipIndex),
              amount: Number(packingslip.mangel2[packingSlipIndex]),
              description: this.CreateCorrectDescription(name),
            })
          }
        }
        for (var packingSlipIndex in packingslip.mangel3) {
          if (packingSlipIndex.replace('Amount', '').toLowerCase() == name.replace('1p', '').replace('2p', '2').toLowerCase() && name != 'customerCode' && name != 'date' && name != 'id') {
            this.totalMangel.push({
              price: this.getPrice(packingSlipIndex),
              amount: Number(packingslip.mangel3[packingSlipIndex]),
              description: this.CreateCorrectDescription(name),
            })
          }
        }
        for (var packingSlipIndex in packingslip.mangel4) {
          if (packingSlipIndex.replace('Amount', '').toLowerCase() == name.replace('1p', '').replace('2p', '2').toLowerCase() && name != 'customerCode' && name != 'date' && name != 'id') {
            this.totalMangel.push({
              price: this.getPrice(packingSlipIndex),
              amount: Number(packingslip.mangel4[packingSlipIndex]),
              description: this.CreateCorrectDescription(name),
            })
          }
        }
        for (var packingSlipIndex in packingslip.badstofVouwer) {
          if (packingSlipIndex.replace('Amount', '').toLowerCase() == name.replace('1p', '').replace('2p', '2').toLowerCase() && name != 'customerCode' && name != 'date' && name != 'id') {
            this.totalMangel.push({
              price:  this.getPrice(packingSlipIndex),
              amount: Number(packingslip.badstofVouwer[packingSlipIndex]),
              description: this.CreateCorrectDescription(name),
            })
          }
        }

      }
      console.log(this.totalMangel)
      // Container weights loop
      for (var i = 0; i < packingslip.containerWeights.length; i++) {
        //@ts-ignore
        if (packingslip.containerWeights[i].containerType === 2) {
                    //@ts-ignore
            //@ts-ignore
            if(packingslip.containerWeights[i].amount === null){
              //@ts-ignore
            packingslip.containerWeights[i].amount = 0;
          }
          this.totalMangel.push({
            //@ts-ignore
            amount: Number(packingslip.containerWeights[i].amount.toFixed(1)),
            description: 'Restaurant',
            price: 0,
          })
        }
        //@ts-ignore
        if (packingslip.containerWeights[i].containerType === 3) {
          //@ts-ignore
          if(packingslip.containerWeights[i].amount === null){
                      //@ts-ignore
            packingslip.containerWeights[i].amount = 0;
          }
          this.totalMangel.push({
            //@ts-ignore
            amount: Number(packingslip.containerWeights[i].amount.toFixed(1)),
            description: 'Bed linnen',
            price: 0
          })
        }
        //@ts-ignore
        if (packingslip.containerWeights[i].containerType === 4) {
          //@ts-ignore
        //@ts-ignore
        if(packingslip.containerWeights[i].amount === null){
          //@ts-ignore
          packingslip.containerWeights[i].amount = 0;
          }
          this.totalMangel.push({
            //@ts-ignore
            amount: Number(packingslip.containerWeights[i].amount.toFixed(1)),
            description: 'Bad linnen',
            price: 0
          })
        }
      }
      // Tafellaken formats loop
      for (var j = 0; j < packingslip.mangelFormats.length; j++) {
          var formatPrice = this.prices.formatPrices.find(x => x.formatId === packingslip.mangelFormats[j].formatId) ? this.prices.formatPrices.find(x => x.formatId === packingslip.mangelFormats[j].formatId).price : 0;
          this.totalMangel.push({
            price: formatPrice,
            amount: Number(packingslip.mangelFormats[j].amount),
            description: this.getFormatDescription(packingslip.mangelFormats[j]),
          })
      }
      // speciale prijzen kleine wasmachine
      for (var k = 0; k < packingslip.mangelSpecials.length; k++) {
        var price = this.prices.specialPrices.find(x => x.specialPriceId === packingslip.mangelSpecials[k].specialId) && this.prices.specialPrices.find(x => x.specialPriceId === packingslip.mangelSpecials[k].specialId) ? this.prices.specialPrices.find(x => x.specialPriceId === packingslip.mangelSpecials[k].specialId).price : 0;
        var description = this.specialPriceTypes.find(x => x.id === packingslip.mangelSpecials[k].specialId) && this.specialPriceTypes.find(x => x.id === packingslip.mangelSpecials[k].specialId) ? this.specialPriceTypes.find(x => x.id === packingslip.mangelSpecials[k].specialId).description : "Verwijderd";
        this.totalMangel.push({
          price: price,
          amount: Number(packingslip.mangelSpecials[k].amount),
          description,
        })
      }
    })
    this.packingslipTotals = Array.from(
      this.totalMangel.reduce((m, { description, amount, price }) => {
        // Check if the description already exists in the map
        if (!m.has(description)) {
          m.set(description, { totalAmount: 0, totalPrice: 0 });
        }
        // Get the current values for this description
        const current = m.get(description);
        // Update the total amount and total price
        current.totalAmount += amount;
        current.totalPrice += (amount * price);
        // Set the updated values back into the map
        m.set(description, current);
        return m;
      }, new Map()).entries(),
      ([description, { totalAmount, totalPrice }]) => ({
        description, 
        totalAmount, 
        totalPrice: parseFloat(totalPrice.toFixed(2)) // Formatting totalPrice to 2 decimal places
      })
    );
    

  this.hasData = true;

  }

  getPrice(propertyName) {
    // Check if the property exists in the JSON
    if (this.prices.hasOwnProperty(propertyName)) {
        return this.prices[propertyName];
    } else {
        // Handle the case where the property does not exist
        return null;
    }
}

  selectEvent(item) {
    this.selectedCustomer = item;
    this.dataService.getPrice(item.customerCode).subscribe((prices: Price) => {
      this.prices = prices;
      this.clean(this.prices)
      //@ts-ignore
      this.dataService.getFormatPrices(this.prices.id).subscribe((res) => {
        this.formatPrices = res;
      })
    })
    this.auto.query = item.customerCode;
  }

  CreateCorrectDescription(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  getFormatDescription(format){
    return "Tafellaken: " + this.sizes.find(x => x.id === format.formatId).size;
  }

  capitalizeFirstLetter(string) 
  {
    if(string !== null){
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
  }

  resetData(){
    this.selectedCustomer = new Customer();
  }

  onChangeSearch(val: string) {
    this.resetData();
  }

  onFocused(e) {
    // do something when input is focused
  }

  clearItem(e) {
    this.resetData();
  }

  clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }
}