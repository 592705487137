export const Bedlinnen = [
    'laken1p',
    'laken2p',
    'hoeslaken',
    'hoeslaken2p',
    'overtrek',
    'overtrek2p',
    'steeklaken',
    'slopen',
    'slopenklein',
    'kussen',
    'dekbed',
]

export const Restaurantlinnen = [
    'servetten',
    'napparons',
    'doeken',
    'lopers',
    'tafellaken'
]

export const Badlinnen = [
    'badmat',
    'baddoek',
    'badlaken',
    'saunalaken',
    'molton',
    'jersey',
    'gastendoek',
    'badjas',
    'washand',
]

export const Bedrijfskleding = [
    'overall',
    'vesttrui',
    'tshirt',
    'deken',
    'koksbroek',
    'koksjas',
    'overhemd',
    'sloof',
    'stofjas',
    'werkbroek',
    'werkjas',
    'keukenhanddoek',
    'bodywarmer',
    'pilotjack',
    'polo',
    'sweater'
]

export const Diversen = [
    'doek',
    'hoezen',
    'amoverall',
    'dweilen',
    'merken',
    'reparaties',
    'werkhandschoenen',
    'schoonloopmat',
    'bedpakketten',
    'bedpakketten2',
    'container',
     'manden',
    'colli',
     'badpakketten',
    'keukenpakketten',
    'transport',
    'tafelrok'
]