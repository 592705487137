import {
    Routes
} from '@angular/router';

import {
    AdminLayoutComponent
} from './layouts/admin/admin-layout.component';

import {
    LoginComponent
} from './login/login.component';
import {
    AuthGuard
} from './authentication/authGuard';
import { HistoryComponent } from './history/history.component';
import { PickOrderDetailsComponent } from './order/pick-order/pick-order-details/pick-order-details.component';
import { PickOrderTemplateComponent } from './order/pick-order/pick-order-template/pick-order-template.component';
import { BankComponent } from './bank/bank.component';
import { UsersComponent } from './users/users.component';
import { CreditHistoryComponent } from './history/credit-history/credit-history.component';

export const AppRoutes: Routes = [{
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [{
                path: 'back',
                loadChildren: () => import('./back/back.module').then(m => m.BackModule)
            },
            {
                path: 'front',
                loadChildren: () => import('./front/front.module').then(m => m.FrontModule)
            },
            {
                path: 'mangelAdmin',
                 loadChildren: () => import('./mangelAdmin/mangelAdmin.module').then(m => m.MangelAdminModule)
            },
            {
                path: 'customer',
                loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)
            },
            {
                path: 'prices',
                loadChildren: () => import('./prices/prices.module').then(m => m.PricesModule)
            },
            {
                path: 'invoice',
                loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule)
            },
            {
                path: 'pickOrder',
                loadChildren: () => import('./order/pick-order/pick-order.module').then(m => m.PickOrderModule)
            },
            {
                path: 'routes',
                loadChildren: () => import('./routes/routes.module').then(m => m.RoutesModule)
            },
            {
                path: 'pickOrderDetail/:id',
                component: PickOrderDetailsComponent
            }, 
            {
                path: 'pickOrderDetail/:id/:customerId',
                component: PickOrderDetailsComponent
            }, 
            {
                path: 'packingSlip/:id',
                component: PickOrderTemplateComponent
            }, 

            {
                path: 'packingSlip/:id/:email',
                component: PickOrderTemplateComponent
            }, 
            {
                path: 'packingSlip/:id/:email/:customerId',
                component: PickOrderTemplateComponent
            },
            {
                path: 'bank',
                component: BankComponent
            },
            {
                path: 'history/packingslips',
                component: HistoryComponent
            },
            {
                path: 'history/creditOrders',
                component: CreditHistoryComponent
            },
            {
                path: 'users',
                component: UsersComponent
            },
        ]
    },
    {
        path: 'login',
        component: LoginComponent
    },
];