import { MangelSpecial } from './mangelSpecial';

export class Mangel4 {
        CustomerCode : string;
        Overall : number;
        AMOverall : number;
        WerkBroek : number;
        WerkJas : number;
        StofJas : number;
        VestTrui : number;
        KoksJas : number;
        KoksBroek : number;
        Sloof : number;
        Overhemd : number;
        Deken: number;
        TafelRok: number;
        Tshirt : number;
        Doek : number;
        Dweil: number;
        Keukenhanddoek: number;
        polo: number;
        sweater: number;
        merken: number;
        werkhandschoenen: number;
        reparaties: number;
        pilotjack: number;
        bodywarmer: number;
        schoonloopmat: number;
        Opmerking : string;
        Date : Date;
        closed : boolean;
        mangelSpecials: MangelSpecial[];
}