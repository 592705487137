import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { RouterModule } from '@angular/router';
//@ts-ignore
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent }   from './app.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { PagesnavbarModule} from './shared/pagesnavbar/pagesnavbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
import { LoginModule } from './login/login.module'
import { AuthGuard } from './authentication/authGuard';
import { AuthService } from './authentication/authService';
import { LoadingScreenInterceptor } from './services/loading-screen/loading.interceptor';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { HistoryComponent } from './history/history.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { DataTablesModule } from 'angular-datatables';
import { PickOrderDetailsComponent } from './order/pick-order/pick-order-details/pick-order-details.component';
import { PickOrderTemplateComponent } from './order/pick-order/pick-order-template/pick-order-template.component';
import { registerLocaleData } from '@angular/common';
import localNL from '@angular/common/locales/nl';
import { BankComponent } from './bank/bank.component'
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import {DropDownListModule} from "@syncfusion/ej2-angular-dropdowns";
import { UsersComponent } from './users/users.component';
import { AuthInterceptor } from './authentication/authInterceptor';
import { CreditHistoryComponent } from './history/credit-history/credit-history.component';
registerLocaleData(localNL, 'nl');
@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: true
        }),
        HttpClientModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        PagesnavbarModule,
        LoginModule,
        AutocompleteLibModule,
        DataTablesModule,
        NgxTypeaheadModule,
        GridModule,
        ButtonModule,
        DropDownListModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        LoadingScreenComponent,
        HistoryComponent,
        PickOrderDetailsComponent,
        PickOrderTemplateComponent,
        BankComponent,
        UsersComponent,
        CreditHistoryComponent
    ],
    providers: [AuthGuard, AuthService, {
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingScreenInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },
    { provide: LOCALE_ID, useValue: "NL" }],
    bootstrap:    [ AppComponent ]
})

export class AppModule { }
