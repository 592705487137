import { CustomerGroup } from './customerGroup';

export class Customer {
     id : number
     customerCode : any;  
      customerName : string;
      customerNameMerged : string;
      invoiceCustomerName: string;
      contact : string;
      address : string;
      zipcode : string;
      city : string;
      phone : string;
      remarks : string;
      maandag: boolean;
      dinsdag: boolean;
      woensdag: boolean;
      donderdag: boolean;
      vrijdag: boolean;
      belt: boolean;
      wasklant: boolean;
      huurklant: boolean;
      contract: boolean;
      email: string;
      factuurEmail: string;
      buitenland: boolean;
      dagen: boolean;
      vatNumber: string;
      incasso: boolean;
      contant: boolean;
      mangelType: boolean;
      badstofType: boolean;
      wasmachineType: boolean;
      direct: boolean;
      week: boolean;
      maand: boolean;s
      mangel1Laken1P : number;
      mangel1Laken2P  : number;
      mangel1Hoeslaken  : number;
      mangel1Hoeslaken2P : number; 
      mangel1Overtrek  : number;
      mangel1Overtrek2P : number; 
      mangel1Steeklaken : number; 
      mangel1Tafellaken : number; 
      mangel2Slopen  : number;
      mangel2SlopenKlein  : number;
      mangel2Servetten  : number;
      mangel2Napparons  : number;
      mangel2Doeken  : number;
      mangel2Lopers  : number;
      mangel2Tafellaken  : number;
      mangel3Laken1P  : number;
      mangel3Laken2P  : number;
      mangel3Hoeslaken  : number;
      mangel3Hoeslaken2P  : number;
      mangel3Overtrek  : number;
      mangel3Overtrek2P  : number;
      mangel3napparons : number; 
      mangel3Tafellaken  : number;
      mangel3Doeken: number;
      mangel4Overall  : number;
      mangel4AmOverall  : number;
      mangel4WerkKoksjas  : number;
      mangel4WerkKoksbroek  : number;
      mangel4VestTrui  : number;
      mangel4TheedoekVaatdoek  : number;
      mangel4Sloven  : number;
      mangel4JasSchortOverhemd  : number;
      mangel4Tshirt  : number;
      mangel4Handdoek  : number;
      badstofbadmat  : number;
      badstofbaddoek  : number;
      badstofbadlaken  : number;
      badstofSaunalaken  : number;
      badstofmolton  : number;
      badstofJersey  : number;
      badstofGastendoek  : number;
      badstofVaatdoek  : number;
      badstofbadjas  : number;
      customerGroupId : number;
      discountPercentage : number;
      locationId: number;
      startDate: Date;
      location: Location;
}
