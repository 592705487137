import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class generateStaticService {
  constructor() { }
    getServices(){
      return  [
        {
          "id": 0,
          "size": "Kies een formaat"
        },
        {
        "id": 1,
        "size": "100*140"
      },
      {
        "id": 2,
        "size": "115*140"
      },
      {
        "id": 3,
        "size": "115*190"
      },
      {
        "id": 4,
        "size": "140*140"
      },
      {
        "id": 5,
        "size": "140*190"
      },
      {
        "id": 6,
        "size": "140*240"
      },
      {
        "id": 7,
        "size": "160*160"
      },
      {
        "id": 8,
        "size": "160*200"
      },
      {
        "id": 9,
        "size": "160*240"
      },
      {
        "id": 10,
        "size": "180*180"
      },
      {
        "id": 11,
        "size": "200*200"
      },
      {
        "id": 12,
        "size": "210*210"
      },
      {
        "id": 13,
        "size": "230*230"
      },
      {
        "id": 14,
        "size": "Rond"
      },
      {
        "id": 15,
        "size": "Normaal"
      },
      {
        "id": 16,
        "size": "206x220"
      },
      {
        "id": 17,
        "size": "228x240"
      },
      {
        "id": 18,
        "size": "270x270"
      },
      {
        "id": 19,
        "size": "130x280"
      },
      {
        "id": 20,
        "size": "170x280"
      }
    ]
    } 

    getContainerTypes(){
      return  [
        {
        "id": 2,
        "value": "Restaurant"
      },
      {
        "id": 3,
        "value": "Bed linnen"
      },
      {
      "id": 4,
      "value": "Bad linnen"
    }]
    }
};