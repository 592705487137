import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { httpOptions, errorHandler } from "../helpers";
import { retry, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Customer } from "../../models/customer";
import { Mangel1 } from "../../models/mangel1";
import { Mangel2 } from "../../models/mangel2";
import { Mangel3 } from "../../models/mangel3";
import { Badstof } from "../../models/badstof";
import { Mangel4 } from "../../models/mangel4";
import { Price } from "../../models/price";
import { SpecialPriceType } from "../../models/specialPriceType";
import { CustomerGroup } from "../../models/customerGroup";
import { PackingSlip } from "../../models/packingSlip";
import { CustomerInvoicePrice } from "../../models/customerInvoicePrice";
import { CustomerInfo } from "../../models/customerInfo";
import { Invoice } from "../../models/invoice";
import { PackingSlipHistoryModel } from "../../models/packingSlipHistoryModel";
import { InvoiceRun } from "../../models/invoiceRun";
import { PriceChangeModel } from "../../models/priceChangeModel";
import { Location } from "../../models/location";
import { User } from "../../models/user";

@Injectable({
  providedIn: "root",
})
export class dataService {
  constructor(private http: HttpClient) {}

  changePrice(priceChanges: PriceChangeModel): Observable<PriceChangeModel> {
    return this.http
      .post<PriceChangeModel>(
        environment.serviceUrl + "/api/price/priceChange",
        priceChanges,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }


  // //Get the data to populate dropdowns
  getCustomerData(location: string): Observable<Customer[]> {
    return this.http
      .get<Customer[]>(
        environment.serviceUrl + "/api/Customers/" + location,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getCustomer(customerCode): Observable<Customer> {
    return this.http
      .get<Customer>(
        environment.serviceUrl +
          "/api/Customers/getCustomerByCustomerCode?customerCode=" +
          customerCode,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  addCustomer(customer): Observable<Customer> {
    return this.http.post<Customer>(
      environment.serviceUrl + "/api/Customers",
      customer,
      httpOptions
    );
  }

  deleteCustomer(customer): Observable<Customer> {
    return this.http.post<Customer>(
      environment.serviceUrl + "/api/Customers/deleteCustomer",
      customer,
      httpOptions
    );
  }

  updateMangel1(mangel1): Observable<Mangel1> {
    return this.http
      .post<Mangel1>(
        environment.serviceUrl + "/api/mangel/updateMangel1",
        mangel1,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  updateMangel2(mangel2): Observable<Mangel2> {
    return this.http
      .post<Mangel2>(
        environment.serviceUrl + "/api/mangel/updateMangel2",
        mangel2,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  updateMangel3(mangel3): Observable<Mangel3> {
    return this.http
      .post<Mangel3>(
        environment.serviceUrl + "/api/mangel/updateMangel3",
        mangel3,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  updateMangel4(mangel4): Observable<Mangel4> {
    return this.http
      .post<Mangel4>(
        environment.serviceUrl + "/api/mangel/updateMangel4",
        mangel4,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  updateBadstofVouwer(badstofVouwer): Observable<Badstof> {
    return this.http
      .post<Badstof>(
        environment.serviceUrl + "/api/mangel/updateBadstofVouwer",
        badstofVouwer,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  updateMangelAdmin(
    mangel1,
    mangel2,
    mangel3,
    mangel4,
    badstofVouwer,
    containerWeights,
    email,
    userId
  ) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/mangel/updateMangelAdmin",
        {
          mangel1,
          mangel2,
          mangel3,
          mangel4,
          badstofVouwer,
          containerWeights,
          email,
          userId,
        },
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  updateMangelAdminManual(
    mangel1,
    mangel2,
    mangel3,
    mangel4,
    badstofVouwer,
    containerWeights,
    email,
    userId,
    isCredit
  ) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/mangel/updateMangelAdminManual",
        {
          mangel1,
          mangel2,
          mangel3,
          mangel4,
          badstofVouwer,
          containerWeights,
          email,
          userId,
          isCredit
        },
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  sendEmail(formData): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + "/api/mangel/mailPackingSlip",
      formData,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  getRoutes(): Observable<any> {
    return this.http
      .get<any>(environment.serviceUrl + "/api/routes", httpOptions)
      .pipe(catchError(errorHandler));
  }

  UpdateDrivers(driversToUpdate): Observable<any> {
    return this.http
      .put<any>(
        environment.serviceUrl + "/api/routes/drivers",
        driversToUpdate,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getRoutesToday(id: number): Observable<any> {
    return this.http
      .get<any>(environment.serviceUrl + "/api/routes/byDay/" + id, httpOptions)
      .pipe(catchError(errorHandler));
  }

  getDrivers(): Observable<any> {
    return this.http
      .get<any>(environment.serviceUrl + "/api/routes/drivers", httpOptions)
      .pipe(catchError(errorHandler));
  }

  getRoutesByCustomer(customerId): Observable<any> {
    return this.http
      .get<any>(
        environment.serviceUrl + `/api/routes/${customerId}`,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getAmountByCustomer(customerId): Observable<any> {
    return this.http
      .get<any>(
        environment.serviceUrl + `/api/routes/amount/${customerId}`,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  addRoute(route): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + `/api/routes/`,
      route,
      httpOptions
    );
  }

  updateRoute(route): Observable<any> {
    return this.http.put<any>(
      environment.serviceUrl + `/api/routes/`,
      route,
      httpOptions
    );
  }

  addDriverAmount(driverAmount: any): Observable<any> {
    return this.http.post<any>(
      environment.serviceUrl + `/api/routes/amount`,
      driverAmount,
      httpOptions
    );
  }

  deleteRoute(id): Observable<any> {
    return this.http.delete(
      environment.serviceUrl + `/api/routes/${id}`,
      httpOptions
    );
  }

  getMangel1(customerCode): Observable<Mangel1> {
    return this.http
      .get<Mangel1>(
        environment.serviceUrl +
          "/api/mangel/getMangel1?customerCode=" +
          customerCode,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }
  getMangel2(customerCode): Observable<Mangel2> {
    return this.http
      .get<Mangel2>(
        environment.serviceUrl +
          "/api/mangel/getMangel2?customerCode=" +
          customerCode,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }
  getMangel3(customerCode): Observable<Mangel3> {
    return this.http
      .get<Mangel3>(
        environment.serviceUrl +
          "/api/mangel/getMangel3?customerCode=" +
          customerCode,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getMangel4(customerCode): Observable<Mangel4> {
    return this.http
      .get<Mangel4>(
        environment.serviceUrl +
          "/api/mangel/getMangel4?customerCode=" +
          customerCode,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getBadstofVouwer(customerCode): Observable<Badstof> {
    return this.http
      .get<Badstof>(
        environment.serviceUrl +
          "/api/mangel/getBadstofVouwer?customerCode=" +
          customerCode,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getMangel1History(): Observable<Mangel1[]> {
    return this.http
      .get<Mangel1[]>(
        environment.serviceUrl + "/api/mangel/getMangel1History",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getMangel2History(): Observable<Mangel2[]> {
    return this.http
      .get<Mangel2[]>(
        environment.serviceUrl + "/api/mangel/getMangel2History",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getMangel3History(): Observable<Mangel3[]> {
    return this.http
      .get<Mangel3[]>(
        environment.serviceUrl + "/api/mangel/getMangel3History",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getMangel4History(): Observable<Mangel4[]> {
    return this.http
      .get<Mangel4[]>(
        environment.serviceUrl + "/api/mangel/getMangel4History",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getBadstofHistory(): Observable<Badstof[]> {
    return this.http
      .get<Badstof[]>(
        environment.serviceUrl + "/api/mangel/getBadstofHistory",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getPackingSlipHistory(
    packingslipHistoryModel: PackingSlipHistoryModel
  ): Observable<any[]> {
    return this.http.post<any[]>(
      environment.serviceUrl + "/api/mangel/getPackingSlipHistory",
      packingslipHistoryModel,
      httpOptions
    );
  }

  getCreditHistory(
    packingslipHistoryModel: PackingSlipHistoryModel
  ): Observable<any[]> {
    return this.http.post<any[]>(
      environment.serviceUrl + "/api/mangel/getCreditHistory",
      packingslipHistoryModel,
      httpOptions
    );
  }

  getContainerWeight(customerCode): Observable<any> {
    var customer = new Customer();
    customer.customerCode = customerCode;
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/mangel/getContainerWeight",
        customer,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  addContainerWeight(object): Observable<any> {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/mangel/addContainerWeight",
        object,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  removeContainerWeight(object): Observable<any> {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/mangel/removeContainerWeight",
        object,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getPrice(customerCode): Observable<any> {
    return this.http
      .get<any>(
        environment.serviceUrl + "/api/price?customerCode=" + customerCode,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getOldPrice(customerCode): Observable<any> {
    return this.http
      .get<any>(
        environment.serviceUrl +
          "/api/price/getOldCustomerPrices?customerCode=" +
          customerCode,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  addPrice(object): Observable<Price> {
    return this.http
      .post<Price>(environment.serviceUrl + "/api/price", object, httpOptions)
      .pipe(catchError(errorHandler));
  }

  getSpecialPriceTypes(): Observable<SpecialPriceType[]> {
    return this.http
      .get<SpecialPriceType[]>(
        environment.serviceUrl + "/api/price/getSpecialPriceTypes",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  addSpecialPriceType(specialPriceType): Observable<SpecialPriceType> {
    return this.http
      .post<SpecialPriceType>(
        environment.serviceUrl + "/api/price/addSpecialPriceType",
        specialPriceType,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getCustomerGroups(): Observable<CustomerGroup[]> {
    return this.http
      .get<CustomerGroup[]>(
        environment.serviceUrl + "/api/Customers/getCustomerGroups",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getLocations(): Observable<Location[]> {
    return this.http
      .get<Location[]>(
        environment.serviceUrl + "/api/Customers/getLocations",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getPackingSlips(): Observable<any> {
    return this.http
      .get<any>(
        environment.serviceUrl + "/api/mangel/getPackingSlips",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getPackingSlipsLight(): Observable<any> {
    return this.http
      .get<any>(
        environment.serviceUrl + "/api/mangel/getPackingSlipsLight",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getCreditsLight(): Observable<any> {
    return this.http
      .get<any>(
        environment.serviceUrl + "/api/mangel/getCreditsLight",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }


  getPackingSlipsLightByCustomerId(customerId): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl +
        "/api/mangel/getPackingSlipsLightByCustomerId?Id=" +
        customerId,
      httpOptions
    );
  }

  getCreditsLightByCustomerId(customerId): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl +
        "/api/mangel/getcreditsLightByCustomerId?Id=" +
        customerId,
      httpOptions
    );
  }

  getPackingSlipsByCustomerId(customerId): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl +
        "/api/mangel/getPackingSlipsByCustomerId?Id=" +
        customerId,
      httpOptions
    );
  }

  getClosedPackingSlipsByCustomerId(customerId): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl +
        "/api/mangel/getClosedPackingSlipsByCustomerId?Id=" +
        customerId,
      httpOptions
    );
  }

  getPackingSlipsForInvoiceByCustomerId(customerId): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl +
        "/api/mangel/getPackingSlipsForInvoiceByCustomerId?Id=" +
        customerId,
      httpOptions
    );
  }

  getPackingSlipsForInvoice(): Observable<any> {
    return this.http.get<any>(
      environment.serviceUrl + "/api/mangel/getPackingSlipsForInvoice",
      httpOptions
    );
  }

  getPackingSlipById(Id): Observable<any> {
    let params = new URLSearchParams();
    params.append("Id", Id);
    return this.http
      .get<any>(
        environment.serviceUrl + "/api/mangel/getPackingSlipById?Id=" + Id,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  deletePackingSlip(packingSlip: PackingSlip) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/mangel/deletePackingSlip",
        packingSlip,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  updatePackingSlip(packingSlip: PackingSlip) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/mangel/updatePackingSlip",
        packingSlip,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  openPackingSlip(packingSlip: PackingSlip) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/mangel/openPackingSlip",
        packingSlip,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  printPackingSlip(packingSlip: PackingSlip) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/mangel/createPDF",
        packingSlip,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  printTotalPackingSlip(packingSlips: any[]) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/mangel/createTotalPDF",
        packingSlips,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getOpenMangels() {
    return this.http
      .get<any>(
        environment.serviceUrl + "/api/mangel/getOpenMangels",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getCustomerPrices() {
    return this.http
      .get<any>(
        environment.serviceUrl + "/api/price/getCustomerPrices",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getCustomerInvoicePrices(customerCode) {
    return this.http
      .get<any>(
        environment.serviceUrl +
          "/api/price/getCustomerInvoicePrices?customerCode=" +
          customerCode,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getOldCustomerInvoicePrices(customerCode) {
    return this.http
      .get<any>(
        environment.serviceUrl +
          "/api/price/getOldCustomerInvoicePrices?customerCode=" +
          customerCode,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getFormatPrices(priceId) {
    return this.http
      .get<any>(
        environment.serviceUrl +
          "/api/price/getFormatPrices?priceId=" +
          priceId,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  addCustomerPrice(customerInvoicePrice: CustomerInvoicePrice) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/price/addCustomerPrice",
        customerInvoicePrice,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  deleteCustomerInvoicePrice(customerInvoicePrice: CustomerInvoicePrice) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/price/deleteCustomerInvoicePrice",
        customerInvoicePrice,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  addCustomerInfo(customerInfo: CustomerInfo) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/customers/addCustomerInfo",
        customerInfo,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getCustomerInfo(customerCode) {
    return this.http
      .get<any>(
        environment.serviceUrl +
          "/api/customers/getCustomerInfo?customerCode=" +
          customerCode,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  // INVOICES

  addInvoice(invoice: Invoice) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/invoice/addInvoice",
        invoice,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  updateInvoice(invoice: Invoice) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/invoice/updateInvoice",
        invoice,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  closeInvoices(invoices: Invoice[]) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/invoice/closeInvoices",
        invoices,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  deleteInvoice(invoice: Invoice) {
    return this.http
      .post<any>(
        environment.serviceUrl + "/api/invoice/deleteInvoice",
        invoice,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getInvoiceById(invoiceId) {
    return this.http
      .get<any>(
        environment.serviceUrl +
          "/api/invoice/getInvoiceById?invoiceId=" +
          invoiceId,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getInvoicesByCustomerId(customerId) {
    return this.http
      .get<any>(
        environment.serviceUrl +
          "/api/invoice/getInvoicesByCustomerId?customerId=" +
          customerId,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getAllInvoicesByCustomerId(customerId) {
    return this.http
      .get<any>(
        environment.serviceUrl +
          "/api/invoice/getAllInvoicesByCustomerId?customerId=" +
          customerId,
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getOpenInvoices() {
    return this.http
      .get<any>(
        environment.serviceUrl + "/api/invoice/getOpenInvoices",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  getInvoicesExact() {
    return this.http
      .get<any>(
        environment.serviceUrl + "/api/invoice/getExactInvoices",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  generateExactFile(invoices) {
    return this.http.post<any>(
      environment.serviceUrl + "/api/invoice/generateExactFile",
      invoices,
      httpOptions
    );
  }

  getClosedInvoices() {
    return this.http
      .get<any>(
        environment.serviceUrl + "/api/invoice/getClosedInvoices",
        httpOptions
      )
      .pipe(catchError(errorHandler));
  }

  generateInvoices(invoiceRun: InvoiceRun) {
    return this.http.post<any>(
      environment.serviceUrl + "/api/invoice/generateInvoices",
      invoiceRun,
      httpOptions
    );
  }

  generateInvoicesByWeek(
    WeekNumbers: string,
    weekNr: number,
    invoiceRun: InvoiceRun
  ) {
    invoiceRun.weekNrs = WeekNumbers;
    return this.http.post<any>(
      environment.serviceUrl + "/api/invoice/generateInvoices/" + weekNr,
      invoiceRun,
      httpOptions
    );
  }

  generateInvoice(invoice: Invoice) {
    return this.http.post<any>(
      environment.serviceUrl + "/api/invoice/generateInvoice",
      invoice,
      httpOptions
    );
  }

  regenerateInvoices() {
    return this.http.post<any>(
      environment.serviceUrl + "/api/invoice/generateAllInvoicePDF",
      "",
      httpOptions
    );
  }

  getAllRoutes() {
    return this.http.get<any>(
      environment.serviceUrl + "/api/routes/all",
      httpOptions
    );
  }
}
