import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable()
export class AuthService {
  constructor(private myRoute: Router) { }
  sendToken(token: string, roles: string, location:string, id: number) {
    localStorage.setItem("LoggedInUser", token)
    localStorage.setItem("__DA_UserId", id.toString())
    localStorage.setItem("__DA_rol", roles)
    localStorage.setItem("__DA_loc", location)
  }
  getToken() {
    return localStorage.getItem("LoggedInUser")
  }
  getRoles() {
    return localStorage.getItem("__DA_rol")
  }
  isLoggednIn() {
    return this.getToken() !== null;
  }
  canShow(roles: string) {
    return this.getRoles().includes(roles)
  }
  logout() {
    localStorage.removeItem("LoggedInUser");
    localStorage.removeItem("__changeDate");
    localStorage.removeItem("__DA_data");
    localStorage.removeItem("__DA_UserId");
    localStorage.removeItem("__DA_loc");
    localStorage.removeItem("__DA_rol");
    this.myRoute.navigate(["login"]);
  }
}