import { Component, OnInit, ViewChild } from '@angular/core';
import { notifier } from '../../../services/helpers';
import { Customer } from '../../../models/customer';
import { Mangel1 } from '../../../models/mangel1';
import { Mangel2 } from '../../../models/mangel2';
import { Mangel3 } from '../../../models/mangel3';
import { Mangel4 } from '../../../models/mangel4';
import { Badstof } from '../../../models/badstof';
import swal from 'sweetalert2';
import { SpecialPrice } from '../../../models/specialPrice';
import { Price } from '../../../models/price';
import { generateStaticService } from '../../../services/data/helper.services';
import { dataService } from '../../../services/data/data.service';
import { SpecialPriceType } from '../../../models/specialPriceType';
import { Subscription } from 'rxjs';
import { containerWeight } from '../../../models/containerWeight';
import { PackingSlip } from '../../../models/packingSlip';
import { ActivatedRoute, Router } from '@angular/router';
import {User} from "../../../models/user";

@Component({
  selector: 'app-pick-order-details',
  templateUrl: './pick-order-details.component.html',
  styleUrls: ['./pick-order-details.component.scss']
})
export class PickOrderDetailsComponent implements OnInit {
  @ViewChild('auto', {
    static: true
  }) auto;
  customers: Customer[];
  containerWeightsAdded: containerWeight[];
  selectedCustomer: Customer;
  history: Mangel1[];
  packingSlip: PackingSlip;
  sizes: any;
  mangel1: any;
  mangel2: any;
  mangel3: any;
  mangel4: any;
  badstofVouwer: any;
  item: any;
  subscription: Subscription;
  // toggles between search or add
  addMode: boolean;
  findMode: boolean;
  containerWeights: any;
  formats: [];
  price2: Price;
  price3: Price;
  price4: Price;
  specialPriceTypes: SpecialPriceType[];
  userId: number;

  constructor(public helperService: generateStaticService, private dataService: dataService, private route: ActivatedRoute, private router: Router) {
    // Loading the config files
  }
  keyword = 'customerCode';
  public customerName = '';
  ngOnInit() {
    this.packingSlip = new PackingSlip();
    this.packingSlip.customer = new Customer();
    this.packingSlip.mangel1 = new Mangel1()
    this.packingSlip.mangel2 = new Mangel2()
    this.packingSlip.mangel3 = new Mangel3()
    this.packingSlip.mangel4 = new Mangel4()
    this.packingSlip.badstofVouwer = new Badstof()
    this.packingSlip.mangelSpecials = [];
    this.packingSlip.mangelFormats = [];
    this.sizes = this.helperService.getServices();
    this.containerWeights = this.helperService.getContainerTypes();
    this.dataService.getSpecialPriceTypes().subscribe(res => {
      this.specialPriceTypes = res;
    });
    this.dataService.getPackingSlipById(this.route.snapshot.paramMap.get('id')).subscribe(res => {
      this.packingSlip =res;
      if(!this.packingSlip.mangel1){
        this.packingSlip.mangel1 = new Mangel1();
      }
      if(!this.packingSlip.mangel3){
        this.packingSlip.mangel3 = new Mangel3();
      }
      if(!this.packingSlip.mangel2){
        this.packingSlip.mangel2 = new Mangel2();
      }
      if(!this.packingSlip.mangel4){
        this.packingSlip.mangel4 = new Mangel4();
      }
      if(!this.packingSlip.badstofVouwer){
        this.packingSlip.badstofVouwer = new Badstof();
      }
      if(this.packingSlip.containerWeights)
      {
        this.containerWeightsAdded = this.packingSlip.containerWeights;
      }
    })

    this.containerWeightsAdded = [];
    this.selectedCustomer = new Customer();
    this.dataService.getCustomerData('Oldenzaal').subscribe(res => {
      this.customers = res
    });
    this.sizes = this.helperService.getServices();
    this.containerWeights = this.helperService.getContainerTypes();
    this.dataService.getSpecialPriceTypes().subscribe(res => {
      this.specialPriceTypes = res;
    });

    this.formats = [];
    this.price2 = new Price();
    this.price3 = new Price();
    this.price4 = new Price();

  }

  getCustomerName(customerCode) {
    return this.customers.find(x => x.customerCode === customerCode).customerName;
  }

  showWeight(weight) {
    return this.containerWeights.find(x => x.id === weight.containerType).value
  }

  removeLine(weight, index) {
    this.dataService.removeContainerWeight(weight).subscribe(() => {
      this.containerWeightsAdded.splice(index, 1);
      notifier('Succesvol verwijderd', 'save', 'info');
    })

  }

  getAmount2(format) {
    if (this.packingSlip.mangelFormats && this.packingSlip.mangelFormats.length > 0) {
      return this.packingSlip.mangelFormats.find(x => x.formatId === format.formatId) ? this.packingSlip.mangelFormats.find(x => x.formatId === format.formatId).amount : null;
    }

  }

  setAmount2(format, value) {
    if (!this.packingSlip.mangelFormats) {
      this.packingSlip.mangelFormats = [];
    }
    var mangelFound = this.packingSlip.mangelFormats.find(x => x.formatId === format.formatId)
    if (mangelFound) {
      mangelFound.amount = value;
    } else {
      //@ts-ignore
      this.packingSlip.mangelFormats.push({
        formatId: format.formatId,
        amount: value
      })
    }
  }

  getAmount3(format) {
    if (this.packingSlip.mangelFormats && this.packingSlip.mangelFormats.length > 0) {
      return this.packingSlip.mangelFormats.find(x => x.formatId === format.formatId) ? this.packingSlip.mangelFormats.find(x => x.formatId === format.formatId).amount : null;
    }

  }

  setAmount3(format, value) {
    if (!this.packingSlip.mangelFormats) {
      this.packingSlip.mangelFormats = [];
    }
    var mangelFound = this.packingSlip.mangelFormats.find(x => x.formatId === format.formatId)
    if (mangelFound) {
      mangelFound.amount = value;
    } else {
      //@ts-ignore
      this.packingSlip.mangelFormats.push({
        formatId: format.formatId,
        amount: value
      })
    }
  }

  selectEvent(item) {
    this.resetData();
    this.dataService.getContainerWeight(item.customerCode).subscribe(res => {
      this.containerWeightsAdded = res;
    });
    this.selectedCustomer = item;
    this.dataService.getMangel1(item.customerCode).subscribe((res: any) => {
        //can be refactored to function to take format and size as parameter, not to long so for now dont bother
        res ? this.mangel1 = res : null;
        this.mangel1.customerCode = this.selectedCustomer.customerCode;
      },
      error => {
        notifier('Er is iets misgegaan, ververst de pagina en anders contact de beheeerder', 'error', 'danger');
        console.log(error)
      })
    this.dataService.getMangel2(item.customerCode).subscribe((mangel2: any) => {
      this.dataService.getPrice(item.customerCode).subscribe(res => {
        this.price2 = res;
        mangel2 ? this.mangel2 = mangel2 : null;

      });
      this.dataService.getMangel3(item.customerCode).subscribe((mangel3: any) => {
        this.dataService.getPrice(item.customerCode).subscribe(res => {
          this.price3 = res;
          mangel3 ? this.mangel3 = mangel3 : null;

        });
      });
      this.dataService.getBadstofVouwer(item.customerCode).subscribe((res: any) => {
        //can be refactored to function to take format and size as parameter, not to long so for now dont bother
        res ? this.badstofVouwer = res : null;
        this.badstofVouwer.customerCode = this.selectedCustomer.customerCode;
      })
    });
    this.dataService.getMangel4(item.customerCode).subscribe((mangel4:any) => {
      this.dataService.getPrice(item.customerCode).subscribe(res => {
        this.price4 = res;
        mangel4 ? this.mangel4 = mangel4 : null;

      });   
    })   
  }

  getSpecialAmount(specialPrice){
    if(this.packingSlip.mangelSpecials && this.packingSlip.mangelSpecials.length > 0){
      return this.packingSlip.mangelSpecials.find(x => x.specialId === specialPrice.specialId) ? this.packingSlip.mangelSpecials.find(x => x.specialId === specialPrice.specialId).amount : null;
    }

  }

  setSpecialAmount(specialPrice, value){
    if(!this.mangel4.mangelSpecials){
      this.mangel4.mangelSpecials = [];
    }
    var mangelFound = this.mangel4.mangelSpecials.find(x => x.specialId === specialPrice.specialPriceType.id)
    if(mangelFound){
      mangelFound.amount = value;
    }
    else{
      //@ts-ignore
      this.mangel4.mangelSpecials.push({ specialId : specialPrice.specialPriceType.id, amount : value})
    }
  }

  getSpecialPriceDescription(specialPrice){
    return this.specialPriceTypes.find(x => x.id === specialPrice.specialId) ? this.specialPriceTypes.find(x => x.id === specialPrice.specialId).description : null;
  }

  getFormat(format) {
    return this.sizes.find(x => x.id === format.formatId).size
  }


  onChangeSearch(val: string) {
    this.resetData();
  }

  onFocused(e) {
    // do something when input is focused
  }

  printPackingSlip(packingSlip: PackingSlip){
    this.router.navigate(['/packingSlip/' + packingSlip.id])
  }

  clearItem(e) {
    this.resetData();
  }

  addMangelAdmin() {
    if (this.selectedCustomer.customerCode === undefined) {
      notifier('Er is geen klant gekozen', 'error', 'danger');
      return;
    }
    swal({
      title: 'Opslaan?',
      text: "Dit zal de pakbon definitief maken",
      type: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-fill btn-success btn-mr-5',
      cancelButtonClass: 'btn btn-fill btn-danger',
      confirmButtonText: 'Opslaan',
      buttonsStyling: false,

    }).then((result) => {
      if (result.value) {
        this.userId =         this.userId = parseInt(localStorage.getItem("__DA_UserId"));
        this.dataService.updateMangelAdmin(
          !Object.keys(this.mangel1).length ? this.mangel1 = '' : this.mangel1,
          !Object.keys(this.mangel2).length ? this.mangel2 = '' : this.mangel2,
          !Object.keys(this.mangel3).length ? this.mangel3 = '' : this.mangel3,
          !Object.keys(this.mangel4).length ? this.mangel4 = '' : this.mangel4,
          !Object.keys(this.badstofVouwer).length ? this.badstofVouwer = '' : this.badstofVouwer,
          this.containerWeightsAdded.length === 0 ? this.containerWeightsAdded = [] : this.containerWeightsAdded, false, this.userId).subscribe(res => {
            notifier('Alle data succesvol bijgewerkt', 'save', 'info');
            this.resetData();
            this.auto.clear();
          },
          error => {
            console.log(error)
            notifier('Er is iets misgegaan, probeer opnieuw of contact de beheeerder', 'error', 'danger');
          })
      }
    })
  }

  closePackingSlip(){
    if(this.route.snapshot.paramMap.get('customerId') !== null){
      this.router.navigate(['/pickOrder/orders/' + this.route.snapshot.paramMap.get('customerId')]) 
    }
    else{
      this.router.navigate(['/pickOrder/orders']) 
    }

  }

  goBack(){
    if(this.route.snapshot.paramMap.get('customerId') !== null && this.route.snapshot.paramMap.get('customerId').charAt(0) !== 'C' && this.route.snapshot.paramMap.get('customerId').charAt(0) !== 'I'){
      this.router.navigate(['/pickOrder/orders/' + this.route.snapshot.paramMap.get('customerId')]) 
    }
    else if (this.route.snapshot.paramMap.get('customerId').charAt(0) === 'C'){
      this.router.navigate(['/pickOrder/closedOrders/' + this.route.snapshot.paramMap.get('customerId').substring(1)]) 
    }
    else if (this.route.snapshot.paramMap.get('customerId').charAt(0) === 'I'){
      this.router.navigate(['/invoice/invoiceWizard']) 
    }
    else{
      this.router.navigate(['/pickOrder/orders']) 
    }
  }

  resetData() {
    this.selectedCustomer = new Customer();
    this.containerWeightsAdded = [];
    this.mangel1 = new Mangel1();
    this.mangel2 = new Mangel2();
    this.mangel3 = new Mangel3();
    this.mangel4 = new Mangel4();
    this.badstofVouwer = new Badstof();
  }

}