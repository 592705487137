import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';  
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadServiceService {
  constructor(private httpClient: HttpClient) { }
  
  public upload(formData): Observable<any>{
    return this.httpClient.post<any>(environment.serviceUrl + '/api/mangel/mailPackingSlip', formData);  
  }

  public uploadInvoice(formData): Observable<any>{
    return this.httpClient.post<any>(environment.serviceUrl + '/api/invoice/mailInvoice', formData);  
  }

  
  public sendPackingSlipHistory(formData): Observable<any>{
    return this.httpClient.post<any>(environment.serviceUrl + '/api/mangel/mailPackingSlipHistory', formData);  
  }

}
