import { MangelFormat } from './mangelFormat';

export class Mangel2 {
        CustomerCode : string;
        Slopen : number;
        SlopenKlein : number;
        Servetten : number;
        Doeken : number;
        Lopers : number;
        Opmerking : string;
        Date : Date;
        closed : boolean;
        front: boolean;
        mangelFormats: MangelFormat[];
}