import { Badstof } from './badstof';
import { containerWeight } from './containerWeight';
import { Mangel1 } from './mangel1';
import { Mangel2 } from './mangel2';
import { Mangel3 } from './mangel3';
import { Mangel4 } from './mangel4';
import { Customer } from './customer';
import { MangelFormat } from './mangelFormat';
import { MangelSpecial } from './mangelSpecial';

export class PackingSlip {
    creationDate: Date;
    badstofVouwer: Badstof;
    containerWeights: containerWeight[];
    mangelFormats: MangelFormat[];
    mangelSpecials: MangelSpecial[];
    packingSlipNr: string;
    mangel1: Mangel1;
    mangel2: Mangel2;
    mangel3: Mangel3;
    mangel4: Mangel4;
    customer : Customer;
    remarks: string;
    closed: boolean;
    invoiced: boolean;
    selected: boolean;
    userId: number;
    id: number;
}