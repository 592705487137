import { Component, OnInit } from '@angular/core';
import { Customer } from '../../../models/customer';
import { containerWeight } from '../../../models/containerWeight';
import { Mangel1 } from '../../../models/mangel1';
import { PackingSlip } from '../../../models/packingSlip';
import { Subscription } from 'rxjs';
import { Price } from '../../../models/price';
import { SpecialPriceType } from '../../../models/specialPriceType';
import { Mangel2 } from '../../../models/mangel2';
import { Mangel3 } from '../../../models/mangel3';
import { Mangel4 } from '../../../models/mangel4';
import { Badstof } from '../../../models/badstof';
import { generateStaticService } from '../../../services/data/helper.services';
import { dataService } from '../../../services/data/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import html2pdf from 'html2pdf.js'
import { Bedlinnen, Restaurantlinnen, Badlinnen, Bedrijfskleding, Diversen } from '../pickingCategories';
import { UploadServiceService } from '../../../services/upload-service.service';
import { notifier } from '../../../services/helpers';

export class keyValue {
  key: string;
  value: string;
}
@Component({
  selector: 'app-pick-order-template',
  templateUrl: './pick-order-template.component.html',
  styleUrls: ['./pick-order-template.component.scss']
})


export class PickOrderTemplateComponent implements OnInit {
  customers: Customer[];
  containerWeightsAdded: containerWeight[];
  selectedCustomer: Customer;
  history: Mangel1[];
  packingSlip: PackingSlip;
  sizes: any;
  mangel1: any;
  mangel2: any;
  mangel3: any;
  mangel4: any;
  bedlinnen: [keyValue];
  badlinnen: [keyValue];
  restaurantlinnen: [keyValue];
  diversen: [keyValue];
  bedrijfskleding: [keyValue];
  badstofVouwer: any;
  item: any;
  subscription: Subscription;
  // toggles between search or add
  addMode: boolean;
  findMode: boolean;
  email: string;
  customerId: string;
  containerWeights: any;
  formats: [];
  price2: Price;
  price3: Price;
  price4: Price;
  specialPriceTypes: SpecialPriceType[];
  changeMode: Boolean = false;
  constructor(public helperService: generateStaticService, private dataService: dataService, private route: ActivatedRoute, private router: Router, private uploadService: UploadServiceService) { }

  changePackingSlip(){
    this.changeMode = !this.changeMode
  }

  updatePackingSlip(){
    this.dataService.updatePackingSlip(this.packingSlip).subscribe(res => {
      notifier('Pakbon succesvol geupdate', 'save', 'info');
    },
    error => {
      notifier('Er is wat mis gegaan met updaten van de pakbon', 'error', 'danger');
    })
    console.log(this.packingSlip.creationDate)
  }

  ngOnInit() {
    this.packingSlip = new PackingSlip();
    //@ts-ignore
    this.bedlinnen = [];
        //@ts-ignore
    this.badlinnen = [];
        //@ts-ignore
    this.restaurantlinnen = [];
        //@ts-ignore
    this.diversen = [];
        //@ts-ignore
    this.bedrijfskleding = []
    this.sizes = this.helperService.getServices();
    this.containerWeights = this.helperService.getContainerTypes();
    this.dataService.getSpecialPriceTypes().subscribe(res => {
      this.specialPriceTypes = res;
    });

    this.packingSlip.mangelFormats
    this.packingSlip.customer = new Customer();
    this.packingSlip.mangel1 = new Mangel1();
    this.packingSlip.mangel2 = new Mangel2();
    this.packingSlip.mangel3 = new Mangel3();
    this.packingSlip.mangel4 = new Mangel4();
    this.packingSlip.mangelFormats = [];
    this.packingSlip.badstofVouwer = new Badstof();
    this.packingSlip.containerWeights = [];
    this.email = this.route.snapshot.paramMap.get('email');
    this.customerId = this.route.snapshot.paramMap.get('customerId')
    this.dataService.getPackingSlipById(this.route.snapshot.paramMap.get('id')).subscribe(res => {
      this.packingSlip =res;
      console.log(this.packingSlip)
      if(!this.packingSlip.mangel1){
        this.packingSlip.mangel1 = new Mangel1();
      }
      if(!this.packingSlip.mangel3){
        this.packingSlip.mangel3 = new Mangel3();
      }
      if(!this.packingSlip.mangel2){
        this.packingSlip.mangel2 = new Mangel2();
      }
      if(!this.packingSlip.mangel4){
        this.packingSlip.mangel4 = new Mangel4();
      }
      if(!this.packingSlip.badstofVouwer){
        this.packingSlip.badstofVouwer = new Badstof();
      }
      if(this.packingSlip.containerWeights)
      {
        this.containerWeightsAdded = this.packingSlip.containerWeights;
      }

      for(var prop in this.packingSlip.mangel1) {
        if(Bedlinnen.includes(prop.toLowerCase())){
          if(this.packingSlip.mangel1[prop] !== null){
            this.bedlinnen.push( { key: prop, value : this.packingSlip.mangel1[prop] })
          }

        }
      }
      for(var prop in this.packingSlip.mangel2) {
        if(Bedlinnen.includes(prop.toLowerCase())){
          if(this.packingSlip.mangel2[prop] !== null){

            this.bedlinnen.push( { key: prop, value : this.packingSlip.mangel2[prop] })
          }
        }
      }
      for(var prop in this.packingSlip.mangel2) {
        if(Restaurantlinnen.includes(prop.toLowerCase())){
          if(this.packingSlip.mangel2[prop] !== null){
            var restaurantlinnenExist = this.restaurantlinnen.find(x => x.key.toLowerCase() === prop.toLowerCase())
            if(restaurantlinnenExist){
              restaurantlinnenExist.value += this.packingSlip.mangel2[prop];
            }
            else{
            this.restaurantlinnen.push( { key: prop, value : this.packingSlip.mangel2[prop] })
            }
          }
        }
      }
      for(var prop in this.packingSlip.mangel3) {
        if(Bedlinnen.includes(prop.toLowerCase())){
          if(this.packingSlip.mangel3[prop] !== null){
            var bedlinnenExist = this.bedlinnen.find(x => x.key.toLowerCase() === prop.toLowerCase())
            if(bedlinnenExist){
              bedlinnenExist.value += this.packingSlip.mangel3[prop];
            }
            else{
             this.bedlinnen.push( { key: prop, value : this.packingSlip.mangel3[prop] })
            }
          }
        }
      }
      for(var prop in this.packingSlip.mangel3) {
        if(Restaurantlinnen.includes(prop.toLowerCase())){
          if(this.packingSlip.mangel3[prop] !== null){
            var restaurantlinnenExist = this.restaurantlinnen.find(x => x.key.toLowerCase() === prop.toLowerCase())
            if(restaurantlinnenExist){
              restaurantlinnenExist.value += this.packingSlip.mangel3[prop];
            }
            else{
            this.restaurantlinnen.push( { key: prop, value : this.packingSlip.mangel3[prop] })
            }
          }
        }
      }
      for(var prop in this.packingSlip.badstofVouwer) {
        if(Bedlinnen.includes(prop.toLowerCase())){
          if(this.packingSlip.badstofVouwer[prop] !== null){
            var bedlinnenExist = this.bedlinnen.find(x => x.key.toLowerCase() === prop.toLowerCase())
            if(bedlinnenExist){
              bedlinnenExist.value += this.packingSlip.badstofVouwer[prop];
            }
            else{
              this.bedlinnen.push( { key: prop, value : this.packingSlip.badstofVouwer[prop] })
            }
          }
        }
      }
      for(var prop in this.packingSlip.badstofVouwer) {
        if(Badlinnen.includes(prop.replace('badstof', '').toLowerCase())){
          if(this.packingSlip.badstofVouwer[prop] !== null){
            this.badlinnen.push( { key: prop.replace('badstof', ''), value : this.packingSlip.badstofVouwer[prop] })
          }
        }
      }
      for(var prop in this.packingSlip.mangel4) {
        if(Bedrijfskleding.includes(prop.toLowerCase())){
          if(this.packingSlip.mangel4[prop] !== null){
            this.bedrijfskleding.push( { key: prop, value : this.packingSlip.mangel4[prop] })
          }
        }
      }
      for(var prop in this.packingSlip.mangel4) {
        if(Diversen.includes(prop.toLowerCase())){
          if(this.packingSlip.mangel4[prop] !== null){
            this.diversen.push( { key: prop, value : this.packingSlip.mangel4[prop] })
          }
        }
      }
      for(var prop in this.packingSlip.mangel1) {
        if(Diversen.includes(prop.toLowerCase().replace('amount', ''))){
          if(this.packingSlip.mangel1[prop] !== null){
            var diversenExist = this.bedlinnen.find(x => x.key.toLowerCase() === prop.toLowerCase())
            if(diversenExist){
              diversenExist.value += this.packingSlip.mangel1[prop];
            }
            else{
            this.diversen.push( { key: prop.toLowerCase().replace('amount', ''), value : this.packingSlip.mangel1[prop] })
            }
          }
        }
      }
    })


    this.containerWeightsAdded = [];
    this.selectedCustomer = new Customer();
    this.dataService.getCustomerData('Oldenzaal').subscribe(res => {
      this.customers = res
    });
    this.sizes = this.helperService.getServices();
    this.containerWeights = this.helperService.getContainerTypes();
    this.dataService.getSpecialPriceTypes().subscribe(res => {
      this.specialPriceTypes = res;
    });

    this.formats = [];
    this.price2 = new Price();
    this.price3 = new Price();
    this.price4 = new Price();
  }

  capitalizeFirstLetter(string) 
    {
      if(string !== null){
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      }

    }

  getSpecialDescription(special){
    if(special.specialId){
      return this.specialPriceTypes.find(x => x.id === special.specialId).description;
    }

  }

  getFormatDescription(format){
    if(format.formatId){
      return this.sizes.find(x => x.id === format.formatId).size
    }
  }

  showWeight(weight) {
    return this.containerWeights.find(x => x.id === weight.containerType).value
  }


  goBack(){
    if(this.route.snapshot.paramMap.get('customerId') !== null){
      this.router.navigate(['/pickOrder/orders/' + this.route.snapshot.paramMap.get('customerId')]) 
    }
    else{
      this.router.navigate(['/pickOrder/orders']) 
    }
  }

  printPackingSlip(){
    var element = document.getElementById('element-to-print');
    var opt = {
      margin:       0.2,
      filename:     this.packingSlip.packingSlipNr + '.pdf',
      image:        { type: 'jpeg', quality: 1 },
      enableLinks:  true,
      html2canvas:  { scale: 2, dpi:192, letterRendering: true },
      jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] ,before: '#page2el' }
    };

    // New Promise-based usage:
    html2pdf().set(opt).from(element).output('pdf').save();
  }

  emailPackingSlip(){
    var element = document.getElementById('element-to-print');
    var opt = {
      margin:       0.2,
      filename:     this.packingSlip.packingSlipNr + '.pdf',
      image:        { type: 'jpeg', quality: 1 },
      enableLinks:  true,
      html2canvas:  { scale: 2, dpi:192, letterRendering: true },
      jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] ,before: '#page2el' }
    };
   html2pdf().set(opt).from(element).outputPdf().then(pdf => {
     
    let formData = new FormData();
    let base64String = btoa(String.fromCharCode(...new Uint8Array(pdf)));
     formData.append('file', btoa(pdf));
     formData.append('customerEmail', this.packingSlip.customer.email);
     formData.append('customerName', this.packingSlip.customer.customerName);
     formData.append('packingSlipNr', this.packingSlip.packingSlipNr);
      this.uploadService.upload(formData).subscribe(res => {
        notifier('Pakbon succesvol verstuurd', 'save', 'info');
      },
      error => {
        notifier('Er is iets misgegaan, probeer opnieuw of contact de beheeerder', 'error', 'danger');
        console.log(error)
      })
    })
  }


}
