import { MangelFormat } from './mangelFormat';

export class Mangel3 {
        CustomerCode : string;
        Slopen: number;
        Servetten: number;
        Laken1P : number;
        Laken2P : number;
        Hoeslaken : number;
        Hoeslaken2P : number;
        Overtrek : number;
        Overtrek2P : number;
        Napparons : number;
        Opmerking : string;
        Date : Date;
        closed : boolean;
        front: boolean;
        mangelFormats: MangelFormat[];
}