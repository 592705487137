import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { httpOptions, errorHandler } from '../helpers'
import { retry, catchError } from 'rxjs/operators';
import  { environment } from '../../../environments/environment'
import { User } from '../../models/user';


@Injectable({
  providedIn: 'root'
})
export class userService {

  constructor(private http: HttpClient) { }
  
  //Add a new user
  addUser(user : User): Observable<User> {
    return this.http.post<User>(environment.serviceUrl + '/api/users/register',{ 
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      password: user.password,
      roles: user.roles,
      location: user.location
     }, httpOptions )
    .pipe(
      retry(1),
      catchError(errorHandler)
    )
  }

  getUsers(): Observable<any> {
    return this.http.get<any>(environment.serviceUrl + '/api/users', httpOptions )
    .pipe(
      retry(1),
      catchError(errorHandler)
    )
  }

  changePassword(userId: number, passWord: string): Observable<any> {
    return this.http.put<any>(environment.serviceUrl + `/api/users/${userId}/change-password`, { "newPassword" : passWord } , httpOptions )
    .pipe(
      retry(1),
      catchError(errorHandler)
    )
  }

  updateUserRoles(userId: number, role: string, location: string): Observable<any> {
    return this.http.put<any>(environment.serviceUrl + `/api/users/${userId}/change-roles`, { "Roles" : role, "Location" : location } , httpOptions )
    .pipe(
      retry(1),
      catchError(errorHandler)
    )
  }


  login(username, passWord): Observable<User> {
    return this.http.post<User>(environment.serviceUrl + '/api/users/authenticate',{ 
      username: username,
      password: passWord
     }, httpOptions )
    .pipe(
      retry(1),
      catchError(errorHandler)
    )
  }
}

