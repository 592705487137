export class Badstof {
        CustomerCode : string;
        BadstofBadmat : number;
        BadstofBaddoek : number;
        BadstofBadlaken : number;
        BadstofSaunalaken : number;
        BadstofMolton : number;
        BadstofJersey : number;
        BadstofGastendoek : number;
        BadstofWashand : number;
        BadstofBadjas : number;
        kussen: number;
        dekbed: number;
        Opmerking : string;
        Date : Date;
        closed : boolean;
}