import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { User } from '../models/user';
import { userService } from '../services/users/user.service';
import { notifier } from '../services/helpers';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})


export class UsersComponent implements OnInit {
  @ViewChild('userModal', {static:false}) modal: ElementRef;
  locations = ['Oldenzaal', 'Utrecht', 'Amsterdam'];
  customerGroups = ['Admin', 'Mangel', 'Driver', 'extern'];
  newUser: User;
  users: User[];
  selectedCustomerGroup: string;
  selectedLocation: string;
  selectedChangeUserCustomerGroup: string;
  selectedChangeUserLocation: string;
  showUserDetail: boolean = false;
  changedUser: User;

  constructor(private userService: userService,private viewportScroller: ViewportScroller,  private ngZone: NgZone) { }

  ngOnInit() {
    this.selectedCustomerGroup = 'Mangel';
    this.selectedLocation = 'Oldenzaal';
    this.newUser = new User('','','','','','');
    this.changedUser =new User('','','','','','');
    this.userService.getUsers().subscribe(users => {
      this.users = users;
      this.newUser.roles = 'Mangel';
      this.newUser.location = 'Oldenzaal';
    })
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  showUser(user:any){

    this.changedUser = user;
    this.selectedChangeUserLocation = user.location;
    this.selectedChangeUserCustomerGroup = user.roles;
    this.showUserDetail = true;
    this.scrollToTop();
  }

  addUser() {
    this.userService.addUser(this.newUser).subscribe(res =>{
      notifier('gebruiker succesvol toegevoegd', 'save', 'info');
      this.resetData();
      this.showUserDetail = false;
    },
    error => {
      notifier('Er is iets misgegaan, zorg dat alle velden gevuld zijn', 'error', 'danger');
      console.log(error)
    })
    }

    changePassword(userId){
      this.userService.changePassword(userId, this.changedUser.password).subscribe(res => {
        notifier('wachtwoord succesvol aangepast', 'save', 'info');
        this.changedUser.password = '';
      },
      error => {
        notifier('Er is iets misgegaan, wachtwoord is niet gewijzigd', 'error', 'danger');
        console.log(error)
      })
    }

    changeUserRoles(userId){
      this.userService.updateUserRoles(userId, this.selectedChangeUserCustomerGroup, this.selectedChangeUserLocation).subscribe(res => {
        notifier('gegevens succesvol aangepast', 'save', 'info');
        var user =this.users.find(x => x.username == this.changedUser.username);
        user.roles = this.selectedChangeUserCustomerGroup;
      },
      error => {
        notifier('Er is iets misgegaan, wachtwoord is niet gewijzigd', 'error', 'danger');
        console.log(error)
      })
    }

  
    resetData() {
      this.newUser =  new User('','','','','','');
    }
}
