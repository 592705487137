import {
    Component,
    OnInit,
    AfterViewInit,
    AfterViewChecked,
    AfterContentInit
} from '@angular/core';
import {
    AuthService
} from '../authentication/authService';

declare var $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    rights: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    rights: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [{
    path: '/front',
    title: 'Vorderseite',
    type: 'sub',
    icontype: 'pe-7s-back-2',
    rights: 'Admin, Mangel',
    children: [{
        path: 'mangel1',
        title: 'Mangel 1 Vorderseite',
        ab: '1',
        rights: 'Admin, Mangel'
    },
        {
            path: 'mangel2',
            title: 'Mangel 2 Vorderseite',
            ab: '2',
            rights: 'Admin, Mangel'
        },
        {
            path: 'mangel3',
            title: 'Mangel 3 Vorderseite',
            ab: '3',
            rights: 'Admin, Mangel'
        }
    ]
},
    {
        path: '/back',
        title: 'Rückseite',
        type: 'sub',
        icontype: 'pe-7s-next-2',
        rights: 'Admin, Mangel',
        children: [{
            path: 'mangel1',
            title: 'Mangel 1 Rückseite',
            ab: '1',
            rights: 'Admin, Mangel'
        },
            {
                path: 'mangel2',
                title: 'Mangel 2 Rückseite',
                ab: '2',
                rights: 'Admin, Mangel'
            },
            {
                path: 'mangel3',
                title: 'Mangel 3 Rückseite',
                ab: '3',
                rights: 'Admin, Mangel'
            },
            {
                path: 'badstofvouwer',
                title: 'Frotteefalter',
                ab: '4',
                rights: 'Admin, Mangel'
            },
            {
                path: 'mangel4',
                title: 'Kleine Waschmaschine',
                ab: '5',
                rights: 'Admin, Mangel'
            },
        ]
    }, {
        path: '/customer',
        title: 'Kundendaten',
        type: 'sub',
        icontype: 'pe-7s-users',
        rights: 'Admin',
        children: [{
            path: 'overview',
            title: 'Kundenübersicht',
            ab: '1',
            rights: 'Admin'
        }, {
            path: 'add',
            title: 'Kunde hinzufügen',
            ab: '2',
            rights: 'Admin'
        },
            {
                path: 'update',
                title: 'Kunde bearbeiten',
                ab: '3',
                rights: 'Admin'
            },
        ]
    }, {
        path: '/prices',
        title: 'Preise',
        type: 'sub',
        icontype: 'pe-7s-ticket',
        rights: 'Admin',
        children: [{
            path: 'mangelPrices',
            title: 'Mangelpreise',
            ab: '1',
            rights: 'Admin'
        },
            {
                path: 'specialPrices',
                title: 'Sonderpreise',
                ab: '2',
                rights: 'Admin'
            },
            {
                path: 'priceChanges',
                title: 'Preise ändern',
                ab: '3',
                rights: 'Admin'
            },
            {
                path: 'priceHistory',
                title: 'Preishistorie',
                ab: '4',
                rights: 'Admin'
            }
        ]
    },
    {
        path: '/mangelAdmin',
        title: 'MangelAdmin',
        type: 'sub',
        icontype: 'pe-7s-key',
        rights: 'Admin, External',
        children: [{
            path: 'admin',
            title: 'Mangel',
            ab: '1',
            rights: 'Admin, External'
        },
            {
                path: 'customerAdmin',
                title: 'Manueller Lieferschein',
                ab: '2',
                rights: 'Admin'
            },
            {
                path: 'adminOverview',
                title: 'Mangelübersicht',
                ab: '3',
                rights: 'Admin'
            }
        ]
    },
    {
        path: '/invoice',
        title: 'Rechnungsstellung',
        type: 'sub',
        icontype: 'pe-7s-cash',
        rights: 'Admin',
        children: [{
            path: 'manualInvoice',
            title: 'Manuelle Rechnung',
            ab: '1',
            rights: 'Admin'
        },
            {
                path: 'invoiceWizard',
                title: 'Rechnungsstellungsvorgang',
                ab: '2',
                rights: 'Admin'
            },
            {
                path: 'openInvoice',
                title: 'Offene Rechnungen',
                ab: '3',
                rights: 'Admin'
            },
            {
                path: 'closedInvoice',
                title: 'Geschlossene Rechnungen',
                ab: '4',
                rights: 'Admin'
            },

        ]
    },
    {
        path: '/pickOrder',
        title: 'Lieferscheine',
        type: 'sub',
        icontype: 'pe-7s-note2',
        rights: 'Admin',
        children: [{
            path: 'orders',
            title: 'Lieferscheine Übersicht',
            ab: '1',
            rights: 'Admin'
        },
        {
            path: 'creditOrders',
            title: 'Rücklieferschein Übersicht',
            ab: '1',
            rights: 'Admin'
        },
            {
                path: 'closedOrders',
                title: 'Lieferscheine suchen',
                ab: '1',
                rights: 'Admin'
            },
        ]
    },
    {
        path: '/routes',
        title: 'Routen',
        type: 'sub',
        icontype: 'pe-7s-way',
        rights: 'Admin, Driver',
        children: [{
            path: 'routesOverview',
            title: 'Routenübersicht',
            ab: '1',
            rights: 'Admin'
        },
        {
            path: 'routes',
            title: 'Routen',
            ab: '1',
            rights: 'Admin, Driver'
        },
        {
            path: 'route-names',
            title: 'Routennamen',
            ab: '1',
            rights: 'Admin'
        }]
    },
    {
        path: '/bank',
        title: 'Exact',
        type: 'link',
        icontype: 'pe-7s-global',
        rights: 'Admin'
    },
    {
        path: '/history',
        title: 'Geschichte',
        type: 'sub',
        icontype: 'pe-7s-date',
        rights: 'Admin',
        children: [{
            path: 'packingslips',
            title: 'Lieferscheinhistorie',
            ab: '1',
            rights: 'admin'
        },
        {
            path: 'creditOrders',
            title: 'Rücklieferschein Historie',
            ab: '1',
            rights: 'admin'
        }]
    },
    {
        path: '/users',
        title: 'Benutzer',
        type: 'link',
        icontype: 'pe-7s-user',
        rights: 'Admin'
    }
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {
    public menuItems: any[];

    constructor(private authService: AuthService) {

    }

    isNotMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        var isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
        this.menuItems = ROUTES.filter(menuItem => menuItem);

        isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

        if (isWindows) {
            // if we are on windows OS we activate the perfectScrollbar function
            $('.sidebar .sidebar-wrapper, .main-panel').perfectScrollbar();
            $('html').addClass('perfect-scrollbar-on');
        } else {
            $('html').addClass('perfect-scrollbar-off');
        }
    }

    doLogout() {
        this.authService.logout();
    }

    hasRights(menuItem) {


        if (menuItem && menuItem.rights.toLowerCase().includes(this.authService.getRoles().toLowerCase())) {
            return true;
        }
        if (menuItem && menuItem.rights === '') {
            return true;
        }
        return false;
    }

    ngAfterViewInit() {
        var $sidebarParent = $('.sidebar .nav > li.active .collapse li.active > a').parent().parent().parent();

        var collapseId = $sidebarParent.siblings('a').attr("href");

        $(collapseId).collapse("show");
    }
}
