import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AuthService } from '../authentication/authService';
declare var $: any;

// DEFAULT http options when sending web requests
export const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${localStorage.getItem("LoggedInUser")}` 
    })
  };

  export const httpMediaOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("LoggedInUser")}` 
    })
  };

  // export const httpPdfOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type':  'application/pdf',
  //   }),
  //   responseType: ResponseContentType.Blob
  // };
     // Error handling
export function errorHandler(error) {
    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = { errorCode: error.status, errorMessage: error.error };
    }
    return throwError(errorMessage);
 }

 export function notifier(message, iconName, type){
   var icon = null;
   if(iconName === 'save'){
     icon = "pe-7s-diskette";
   }
   if(iconName === 'error'){
     icon = "pe-7s-attention";
   }
   if(iconName == 'deleted'){
     icon = 'pe-7s-trash';
   }
  $.notify({
    icon: icon,
    message: message

  }, {
    type: type,
    timer: 200,
    placement: {
      from: "top",
      align: "center"
    }
  });
}

export function isMandatory(value, type){
  if (!value) {
    notifier(`${type} is mandatory`, 'error', 'danger')
    return false;
  }
  return true;
}

export function isMandatoryCombo(value1, value2, type, type2){
  if (!value1 && !value2) {
    notifier(`${type} or ${type2} is mandatory, one of them needs to contain data`, 'error', 'danger')
    return false;
  }
  return true;
}

export function storageAvailable() {
  const storage = window.localStorage;
  const x = '__storage_test__';

  try {
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
      return e instanceof DOMException && (
          // everything except Firefox
          e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
          // acknowledge QuotaExceededError only if there's something already stored
          storage && storage.length !== 0;
  }
}


